<template>
  <module
    ref="module"
    id="validations"
    titleIcon="fa fa-sign-in"
    :title="$t('validations')"
    :use-default-list="false"
  >
    <div slot="global">
      <!-- In progress -->
      <div v-if="inprogress" class="text-center">
        <h2>{{ inprogresstxt }}</h2>
        <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda"></i>
      </div>

      <div v-else class="row">
        <!-- Modal for Confirm Removal -->
        <modal :show.sync="modalconfirm" headerClasses="justify-content-center">
          <h4 slot="header" class="title title-up">{{ $t("delacctitle") }}?</h4>

          <div class="row">
            <div class="col-12">
              {{ $t("confirmdelacc") }}
            </div>
          </div>

          <template slot="footer">
            <div class="left-side">
              <p-button @click.prevent="confirmedDel" link>{{
                $t("yestxt")
              }}</p-button>
            </div>
            <div class="divider"></div>
            <div class="right-side">
              <p-button type="danger" v-on:click="modalconfirm = false" link>{{
                $t("notxt")
              }}</p-button>
            </div>
          </template>
        </modal>
        <!-- End Modal -->

        <modal :show.sync="modalup" headerClasses="justify-content-center">
          <h4 slot="header" class="title title-up">
            {{ $t("upaccounttitle") }}
          </h4>

          <div class="row text-center">
            <div class="col-12">
              <fg-input
                v-model="acc.account"
                addon-left-icon="fa fa-archive"
                type="number"
                :placeholder="helper_accentry"
              ></fg-input>
            </div>
          </div>

          <div class="row text_center">
            <div class="col-12">
              <fg-input
                v-model="acc.broker"
                addon-left-icon="fa fa-building"
                type="text"
                :placeholder="helper_accbroker"
                v-on:keyup="searchBrokers"
              ></fg-input>
              <ul v-if="showAutoResultsBroker" class="auto-ul">
                <li
                  v-for="r in autoResultsBroker"
                  v-on:click="pickedBroker(`${r}`)"
                >
                  {{ r }}
                </li>
              </ul>
            </div>
          </div>

          <div class="row text-center" v-if="acc.broker">
            <div class="col-12">
              <fg-input
                v-model="acc.server"
                addon-left-icon="fa fa-server"
                type="text"
                :placeholder="helper_accserver"
                v-on:keyup="searchServers"
              ></fg-input>
              <ul v-if="showAutoResults" class="auto-ul">
                <li v-for="r in autoResults" v-on:click="pickedServer(`${r}`)">
                  {{ r }}
                </li>
              </ul>
            </div>
          </div>

          <div class="row text-center">
            <div class="col-12">
              <fg-input
                v-model="acc.password"
                addon-left-icon="fa fa-key"
                type="text"
                :placeholder="helper_accpwd"
              ></fg-input>
            </div>
          </div>

          <template slot="footer">
            <div class="left-side">
              <p-button @click.prevent="validate" link>{{
                $t("upentrybtn")
              }}</p-button>
            </div>
            <div class="divider"></div>
            <div class="right-side">
              <p-button type="danger" v-on:click="modalup = false" link>{{
                $t("cancel")
              }}</p-button>
            </div>
          </template>
        </modal>
        <!-- End Modal -->

        <!--- Modal for Force Validation -->
        <modal :show.sync="modalValidation" headerClasses="justify-content-center">
          <h4 slot="header" class="title title-up">
            {{ $t('forcerevalidation') }}
          </h4>

          <div class="row">
            <div class="col-6">
              <fg-input
                :label="`${$t('accountNumber')}`"
                readonly="true"
                v-model="accountToforcedValidation.account">
              </fg-input>
            </div>
            <div class="col-6">
              <fg-input
                :label="`${$t('brokerservers')}`"
                readonly="true"
                v-model="accountToforcedValidation.server">
              </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <fg-input
                :label="`${$t('usertxt')}`"
                readonly="true"
                v-model="accountToforcedValidation.userid">
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
            </div>
            <div class="col-8">
              <fg-input
              :label="`${$t('revalidationsymbol')}`"
              :disabled="accountToforcedValidation.platform == 'mt4'"
              v-model="accountToforcedValidation.symbol">
              </fg-input>
            </div>
          </div>

          <template slot="footer">
            <div class="left-side">
              <p-button type="success" v-on:click="forceValidationByAccount(accountToforcedValidation)" link>{{ $t('confirm') }}</p-button>
            </div>
            <div class="divider"></div>
            <div class="right-side">
              <p-button type="danger" v-on:click="cleanForcedValidationModal()" link>{{ $t('cancel') }}</p-button>
            </div>
          </template>
        </modal>
        <div class="col-12 text-center" v-if="alert_me">
          <div
            :class="alert_class"
            class="ml-2 mb-2"
            style="display: block; font-weight: bold"
          >
            {{ alert_msg }}
          </div>
        </div>

        <div class="col-md-12">
          <div class="row">
            <div class="col-sm-2">
              <label> {{ sortbyTxt }} </label>
              <el-select
                v-model="sortby.column"
                :placeholder="sortbyTxt"
                v-on:change="sortByCol"
              >
                <el-option
                  class="select-default"
                  v-for="item in sortby.columns"
                  :key="item.prop"
                  :label="item.label"
                  :value="item.prop"
                >
                </el-option>
              </el-select>
            </div>

            <div class="col-sm-2">
              <label> {{ perpageTxt }} </label>
              <el-select v-model="pagination.perPage" :placeholder="perpageTxt">
                <el-option
                  class="select-default"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>

            <div class="col-sm-2">
              <label> {{ sortbyOrder }} </label>
              <el-select
                v-model="sortby.order"
                :placeholder="sortbyOrder"
                v-on:change="sortByCol"
              >
                <el-option
                  class="select-default"
                  v-for="item in sortby.orders"
                  :key="item.prop"
                  :label="item.label"
                  :value="item.prop"
                >
                </el-option>
              </el-select>
            </div>

            <div class="col-sm-6">
              <div class="pull-right">
                <label> {{ searchTxt }} </label>
                <fg-input
                  class="input-sm"
                  v-model="searchQuery"
                  addon-right-icon="nc-icon nc-zoom-split"
                >
                </fg-input>
              </div>
            </div>
            <div class="col-sm-12 mt-2">
              <el-table
                class="table-striped"
                :data="queriedData"
                border
                style="width: 100%"
              >
                <el-table-column :label="actionlbl" min-width="280" width="280">
                  <div slot-scope="scope" class="text-center">
                    <div class="btn-group">
                      <!--
                      <p-button type="primary" link v-on:click="done(`${scope.row.id}`, `${scope.row.strat_name}`)" v-if="scope.row.confirmed">
                        <i class="fa fa-check-square-o" style="font-size: x-large;"></i>
                      </p-button>
                      -->
                      <manual-validation :account="scope.row.account"></manual-validation>
                      <el-tooltip :content="`${$t('copy_pass')}`" placement="top">
                        <p-button
                          type="success"
                          link
                          v-on:click="
                            showpass(`${scope.row.id}`, `${scope.row.password}`)
                          "
                        >
                          <i class="fa fa-key" style="font-size: x-large"></i>
                        </p-button>
                      </el-tooltip>
                      <el-tooltip :content="`${$t('edit_data')}`" placement="top">
                        <p-button
                          type="success"
                          link
                          v-on:click="modify(`${scope.row.id}`)"
                        >
                          <i class="fa fa-pencil" style="font-size: x-large"></i>
                        </p-button>
                      </el-tooltip>
                      <el-tooltip :content="`${$t('force_validation_data')}`" placement="top">
                        <p-button v-if="!scope.row.existingServer"
                          type="success"
                          link
                          v-on:click="addnew(`${scope.row.id}`)"
                        >
                          <i class="fa fa-plus" style="font-size: x-large"></i>
                        </p-button>
                      </el-tooltip>
                      <el-tooltip :content="`${$t('delete_account')}`" placement="top">
                        <p-button
                          type="danger"
                          link
                          v-on:click="delacc(`${scope.row.id}`)"
                        >
                          <i class="fa fa-ban" style="font-size: x-large"></i>
                        </p-button>
                      </el-tooltip>
                      <el-tooltip :content="`${$t('change_status_failed')}`" placement="top" v-if="scope.row.status !== 'failed'">
                        <p-button
                          type="danger"
                          link
                          v-on:click="setStatusFailed(`${scope.row.userid}`)"
                        >
                          <i class="fa fa-times" style="font-size: x-large"></i>
                        </p-button>
                      </el-tooltip>

                    </div>
                  </div>
                </el-table-column>

                <el-table-column :label="`${this.$t('account')}`">
                  <template slot-scope="scope">
                    <span
                      v-on:click="copyit(scope.row.account)"
                      class="clickable"
                    >
                      {{ scope.row.account }} ({{
                        scope.row.platform.toUpperCase()
                      }})
                    </span>
                  </template>
                </el-table-column>

                <el-table-column :label="`${this.$t('userid')}`">
                  <template slot-scope="scope">
                    {{ scope.row.userid }}
                  </template>
                </el-table-column>

                <el-table-column :label="`${this.$t('fullname')}`">
                  <template slot-scope="scope">
                    {{ scope.row.first_name }} {{ scope.row.last_name }}
                  </template>
                </el-table-column>

                <el-table-column :label="`${this.$t('servertxt')}`">
                  <template slot-scope="scope">
                    <span
                      v-on:click="copyit(scope.row.server)"
                      class="clickable"
                    >
                      {{ scope.row.server }}
                    </span>
                  </template>
                </el-table-column>

                <el-table-column :label="`${this.$t('status')}`">
                  <template slot-scope="scope">
                    <span v-if="!scope.row.password">
                      {{$t('pendingpasswordtxt')}}
                    </span>
                    <span v-else-if="scope.row.status==='failed'">
                      {{ $t('failedvalidationtxt') }}
                    </span>
                    <span v-else-if="scope.row.status==='pending'">
                      {{ $t('pendingvalidationtxt') }}
                    </span>
                    <span v-else-if="scope.row.status==='disconnected'">
                      {{ $t('disconnected') }}
                    </span>
                    <span v-else-if="scope.row.status==='validating'|| scope.row.status==='checkingip'">
                      {{ $t('pendingvalidationtxt') }}
                    </span>
                  </template>
                </el-table-column>

                <el-table-column :label="`${this.$t('ip')}`">
                  <template slot-scope="scope">
                    {{ scope.row.host }}
                  </template>
                </el-table-column>

                <el-table-column :label="`${this.$t('date')}`">
                  <template slot-scope="scope">
                    {{ scope.row.addedDt }}
                  </template>
                </el-table-column>
              </el-table>

              <input
                id="clipit"
                type="text"
                v-model="clipboard"
                style="display: none"
              />
            </div>
            <div class="col-sm-6 pagination-info">
              <p class="category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <div class="col-sm-6">
              <p-pagination
                class="pull-right"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="pagination.total"
              >
              </p-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </module>
</template>
<script>
import Vue from "vue";
import {Table, TableColumn, Select, Option, Tooltip} from "element-ui";
import PPagination from "src/components/UIComponents/Pagination.vue";
import { Button, Modal } from "src/components/UIComponents";
import ManualValidation from "@/components/Dashboard/Views/Dashboard/ChangeLogs/manual-validation.vue";

Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Select);
Vue.use(Option);
Vue.use(require("vue-moment"));

const user_data = JSON.parse(localStorage.getItem("user-info"));

let bills = [];
let timeoutcount = 1500;

export default {
  components: {
    ManualValidation,
    PPagination,
    [Button.name]: Button,
    Modal,
    [Tooltip.name]: Tooltip,
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        this.pagination.total = this.tableData.length;
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          //let rowValue = row[key].toString()
          let searchRegex = new RegExp(this.searchQuery, "gi");
          //if (rowValue.includes && rowValue.includes(this.searchQuery.toLowerCase())) {
          if (row[key].toString().match(searchRegex) !== null) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });
      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    },
  },
  data() {
    return {
      jsdata: {
        server: "",
        platform: "",
        broker: "",
      },
      perpageTxt: this.$t("perpage"),
      sortbyTxt: this.$t("sortby"),
      sortbyOrder: this.$t("sortbyorder"),
      searchTxt: this.$t("searchtxt"),
      colSort: "status",
      inprogress: true,
      actionlbl: this.$t("action"),
      alert_me: false,
      alert_msg: null,
      inprogresstxt:
        this.$t("loadingtxt") +
        " " +
        this.$t("validations") +
        ". " +
        this.$t("pleasewait"),
      alert_class: "text-danger",
      modalup: false,
      acc: {},
      acctomodify: "",
      helper_accentry: this.$t("helper_accentry"),
      helper_accserver: this.$t("helper_accserver"),
      helper_accbroker: this.$t("helper_accbroker"),
      helper_accpwd: this.$t("helper_accpwd"),
      autoResultsBroker: [],
      autoResults: [],
      showAutoResultsBroker: false,
      alert_me_modal: false,
      brokers: [],
      brokerlist: [],
      showAutoResults: false,
      sortby: {
        column: "status",
        order: "asc",
        orders: [
          {
            prop: "asc",
            label: this.$t("ascending"),
          },
          {
            prop: "desc",
            label: this.$t("descending"),
          },
        ],
        columns: [
          {
            prop: "userid",
            label: this.$t("userid"),
          },
          {
            prop: "first_name",
            label: this.$t("firstname"),
          },
          {
            prop: "last_name",
            label: this.$t("lastname"),
          },
          {
            prop: "account",
            label: this.$t("account"),
          },
          {
            prop: "server",
            label: this.$t("servertxt"),
          },
          {
            prop: "status",
            label: this.$t("status"),
          },
          {
            prop: "host",
            label: this.$t("ip"),
          },
        ],
      },
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: [
        "userid",
        "first_name",
        "last_name",
        "account",
        "server",
        "status",
        "host",
      ],
      tableColumns: [
        {
          prop: "userid",
          label: this.$t("userid"),
        },
        {
          prop: "first_name",
          label: this.$t("fullname"),
          formatter: function (row, column, value) {
            return `${row.first_name} ${row.last_name})`;
          },
        },
        {
          prop: "server",
          label: this.$t("servertxt"),
        },
        {
          prop: "status",
          label: this.$t("status"),
        },
        {
          prop: "addedDt",
          label: this.$t("date"),
        },
      ],
      tableData: [],
      modalconfirm: false,
      passid: null,
      did: null,
      clipboard: "",
      modalValidation: false,
      accountToforcedValidation: {}
    };
  },
  methods: {
    callManualValidation() {
      let success = resp => {
        console.log(resp)
      }
      let fail = resp => {
        console.log(resp)
      }

      this.$manualValidation(123123).then(success, fail)
    },
    copyit(value) {
      let cb = document.getElementById("clipit");
      cb.style.display = "block";
      cb.value = value;
      cb.select();
      document.execCommand("copy");
      cb.style.display = "none";
      this.$toast.success(`${value} Copied!`);
    },
    closeModalAlert() {
      this.modalconfirm = false;
      this.did = null;
      this.acc = {};
      this.acctomodify = "";
    },
    completedTask() {
      this.$notifyAccountRemovedFromValidations({
        'userid': this.acc.userid,
        'account_number': this.acc.account,
        'broker_name': this.acc.broker
      })
      this.closeModalAlert();
      setTimeout(this.getValids, timeoutcount);
    },
    confirmedDel() {
      this.$delAccount({
        userid: this.acc.userid,
        account: this.acc.account,
        platform: this.acc.platform,
        server: this.acc.server,
      }).then(this.completedTask, this.failop);
    },
    updatedAssets() {
      this.modalassets = false;
      this.alert_me_modal = false;
      this.alert_msg = null;
      this.alert_class = "text-danger";

      this.assets = null;
      this.msid = null;
      this.aid = null;
      this.uid = null;
      this.completed = false;
    },
    updateStrategy() {
      this.$upsertStrategy(
        this.uid,
        this.master.settings,
        this.msid,
        this.aid
      ).then(this.updatedAssets, this.failop);
    },
    done(id) {
      this.did = id;
      this.modalconfirm = true;
    },
    resetAlert() {
      this.alert_me = false;
      this.alert_msg = null;
      this.alert_class = "text-danger";
    },
    cleanForcedValidationModal(){
      this.accountToforcedValidation = {}
      this.modalValidation = false
    },
    forceValidationByAccount(row){
      const faildRevalidation = error => {
        this.$toast.error(this.$t('ERROR_UNEXPECTED'));
        this.cleanForcedValidationModal()
        this.getValids()
      };

      const successForced = resp => {
        this.cleanForcedValidationModal()
        this.$toast.info(this.$t('helper_upentry_done'));
        this.inprogress = true;
        this.getValids()
      }

      this.$forceAccountToValidation(row.account).then(successForced, faildRevalidation)
    },
    setStatusFailed(userid) {

        const success = resp => {
            if (resp.success) {
                this.getValids()
                this.$toast.info(this.$t('helper_upentry_done'));
            }
        }
        const failop = error => {
            this.$toast.error(this.$t('ERROR_UNEXPECTED'));
        }
        this.$setFailedStatus(userid).then(success, failop)
    },
    delacc(id) {
      this.did = id;
      const midx = this.tableData.findIndex(
        (t) => parseInt(t.id) === parseInt(this.did)
      );
      if (midx >= 0) {
        this.acc = this.tableData[midx];
        this.modalconfirm = true;
      }
    },
    modify(id) {
      this.did = id;
      const midx = this.tableData.findIndex(
        (t) => parseInt(t.id) === parseInt(this.did)
      );
      if (midx >= 0) {
        this.acc = this.tableData[midx];
        this.modalup = true;
        this.acctomodify = this.acc.account;
      }
    },
    pickedServer(server) {
      this.acc.server = server;
      this.showAutoResults = false;
      this.autoResults = [];
    },
    pickedBroker(broker) {
      this.acc.broker = broker;
      this.showAutoResultsBroker = false;
      this.autoResultsBroker = [];
    },
    loadBrokers(resp) {
      this.brokers = [];
      this.brokerlist = [];
      for (let j = 0; j < resp.data.length; j++) {
        if (
          this.brokerlist.findIndex((bl) => bl.prop == resp.data[j].broker) < 0
        ) {
          this.brokerlist.push({
            prop: resp.data[j].broker,
            label: resp.data[j].broker,
          });
        }
        this.brokers.push({
          id: resp.data[j].server,
          name: resp.data[j].server,
          broker: resp.data[j].broker,
        });
      }
      if (this.brokerlist.length > 0) {
        this.brokerlist.push({ prop: "Other", label: "Other" });
      }
      this.inprogress = false;
    },
    loadValidations(resp) {
      this.tableData = [];
      for (let i = 0; i < resp.data.length; i++) {
        let s = resp.data[i]
        s.addedDt = this.$moment(s.addedDt * 1000).format("YYYY-MM-DD HH:mm:SS")
        this.tableData.push(s)
      }
      this.sortByCol();
      this.$getServers().then(this.loadBrokers, this.failop);
    },
    showpass(id, pwd) {
      const el = document.createElement("textarea");
      el.value = pwd;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      if (this.passid !== null && this.passid !== id) {
        const prid = this.tableData.findIndex((t) => t.id === this.passid);
        this.tableData[prid].showingpass = false;
      }
      const rid = this.tableData.findIndex((t) => t["id"] == id);
      if (rid >= 0) {
        this.passid = rid;
        this.tableData[rid].showingpass = true;
        const removeShowingPass = (id) => {
          this.tableData[rid].showingpass = false;
          this.passid = null;
        };
        setTimeout(removeShowingPass, timeoutcount);
      }
      this.$toast.success(this.$t("password") + " " + this.$t("copied"));
    },
    brokerSuccess(resp) {
      this.$toast.success(this.$t(resp.msg));
    },
    addnew(id) {
      this.did = id;
      const midx = this.tableData.findIndex(
        (t) => parseInt(t.id) === parseInt(this.did)
      );
      if (midx >= 0) {
        this.acc = this.tableData[midx];
      }
      this.jsdata.server = this.acc.server;
      this.jsdata.broker = this.acc.broker;
      this.jsdata.platform = this.acc.platform;
      const data = this.jsdata;
      this.$addBroker({ data }).then(this.brokerSuccess, this.failop);
    },
    sortItNum(a, b) {
      if (this.sortby.order === "asc") {
        return a[this.colSort] - b[this.colSort];
      }
      return b[this.colSort] - a[this.colSort];
    },
    sortIt(a, b) {
      if (this.sortby.order === "asc") {
        if (a[this.colSort] < b[this.colSort]) return -1;
        if (a[this.colSort] > b[this.colSort]) return 1;
      }
      if (b[this.colSort] < a[this.colSort]) return -1;
      if (b[this.colSort] > a[this.colSort]) return 1;
      return 0;
    },
    sortByCol() {
      this.colSort = this.sortby.column;
      this.tableData.sort(this.sortIt);
    },
    failop(error) {
      if (error.data !== undefined && error.data.msg !== undefined) {
        error = error.data.msg;
      }
      this.inprogress = false
      this.$toast.error(this.$t(error))
    },
    searchBrokers(k) {
      if (
        this.acc.broker !== undefined &&
        this.acc.broker !== null &&
        this.acc.broker.length > 0
      ) {
        this.autoResultsBroker = [];

        for (let i = 0; i < this.brokerlist.length; i++) {
          let r = new RegExp(this.acc.broker, "gi");
          if (this.brokerlist[i].label.match(r) !== null) {
            this.autoResultsBroker.push(this.brokerlist[i].label);
          }
        }
        if (this.autoResultsBroker.length > 0) {
          this.showAutoResultsBroker = true;
        } else {
          this.showAutoResultsBroker = false;
        }
      } else {
        this.showAutoResultsBroker = false;
      }
    },
    searchServers(k) {
      if (
        this.acc.server !== undefined &&
        this.acc.server !== null &&
        this.acc.server.length > 0
      ) {
        this.autoResults = [];

        for (let i = 0; i < this.brokers.length; i++) {
          let r = new RegExp(this.acc.server, "gi");
          if (this.brokers[i].name.match(r) !== null) {
            if (this.brokers[i].broker == this.acc.broker) {
              this.autoResults.push(this.brokers[i].name);
            }
          }
        }
        if (this.autoResults.length > 0) {
          this.showAutoResults = true;
        } else {
          this.showAutoResults = false;
        }
      } else {
        this.showAutoResults = false;
      }
    },
    getValids() {
      this.$getValidations().then(this.loadValidations, this.failop);
    },
    accountUpdated(resp) {
      this.$toast.success(this.$t(resp.msg));
      this.modalup = false;
      this.getValids();
    },
    validate() {
      if (
        this.acc.account !== null &&
        this.acc.account !== "" &&
        this.acc.server !== null &&
        this.acc.server !== "" &&
        this.acc.password !== null &&
        this.acc.password !== ""
      ) {
        const json_data = {
          password: this.acc.password,
          platform: this.acc.platform,
          broker: this.acc.broker,
          server: this.acc.server,
          centaccount: this.acc.centaccount,
          newAccount: this.acc.account,
        };
        this.$addAccount_v2({
          eml: this.acc.userid,
          account: this.acctomodify,
          newacc: this.newAccount,
          json_data: json_data,
        }).then(this.accountUpdated, this.failop);
      } else {
        this.modalup = false;
        this.failop(this.$t("populatefieldbelow"));
      }
    },
  },
  mounted() {
    // Admin User or NOT
    if (user_data["roles"].indexOf("admin") >= 0) {
      this.adminusr = true;
    }
    this.ueml = user_data.email;

    this.getValids();
  },
  created() {
    this.$setTranslatingInProgress();
  },
  beforeDestroy() {
    this.$root.$off("showTranslatingProgress");
  },
};
</script>
<style>
.auto-ul {
  list-style: none;
  padding: 5px;
  overflow: scroll;
  height: 100px;
  position: absolute;
  background-color: white;
  z-index: 9999;
  border: 1px solid silver;
  width: 93%;
}
.auto-ul li:hover {
  background: silver;
  cursor: pointer;
}
</style>
